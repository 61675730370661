
const pages = {};

pages["/broken"] = {};
pages["/broken"].data = () => import("/@react-pages/pages/broken");
pages["/broken"].staticData = {"main":{"sourceType":"js"}};

pages["/"] = {};
pages["/"].data = () => import("/@react-pages/pages/index__");
pages["/"].staticData = {"main":{"sourceType":"js"}};

pages["/quests/:id"] = {};
pages["/quests/:id"].data = () => import("/@react-pages/pages/quests/:id");
pages["/quests/:id"].staticData = {"main":{"sourceType":"js"}};
export default pages;
